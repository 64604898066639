import { whenInView } from '../shared/utilities/when-in-view';
import { screenSmallerThan } from '../shared/utilities/screenSize';

function addClassToAnimate(elements) {
	elements?.forEach(component => {
		whenInView(component).then(() => {
			component.classList.add('active-load-animation');
		});
	});
}
function animateOnceWhenInView() {
	if (screenSmallerThan('md')) {
		const elementsToAnimateOnMobile = document.querySelectorAll('[data-load-animation-mobile]');
		addClassToAnimate(elementsToAnimateOnMobile);
	} else {
		const elementsToAnimateOnDesktop = document.querySelectorAll('[data-load-animation]');
		addClassToAnimate(elementsToAnimateOnDesktop);
	}
}

animateOnceWhenInView();

export default animateOnceWhenInView;
