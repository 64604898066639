export const defaultWidths = {
	sm: 768,
	md: 1024,
	lg: 1280,
	xl: 1400
};

// getBreakpoint :: Void -> String
export const getBreakpoint = () =>
	window
		.getComputedStyle(document.querySelector('body'), ':before')
		.getPropertyValue('content')
		.replace(/"/g, '');

// function :: String -> ?Object -> Boolean
export function screenSmallerThan(size, widths = defaultWidths) {
	if (!Object.prototype.hasOwnProperty.call(widths, size)) {
		throw new Error(`size must be one of: ${Object.keys(widths).join(', ')}`);
	}
	return window.innerWidth < widths[size];
}

export function screenGreatOrEqualTo(size, widths = defaultWidths) {
	if (!Object.prototype.hasOwnProperty.call(widths, size)) {
		throw new Error(`size must be one of: ${Object.keys(widths).join(', ')}`);
	}
	return window.innerWidth >= widths[size];
}
