export function whenInView(elm, options = { threshold: 0 }) {
	return new Promise(acc => {
		import('intersection-observer').then(() => {
			const obs = new IntersectionObserver(entries => {
				if (entries[0].isIntersecting && entries[0].intersectionRatio >= options.threshold) {
					acc();
					obs.disconnect();
				}
			}, options);
			obs.observe(elm);
		});
	});
}
